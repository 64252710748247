import React, { useEffect } from "react";
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import RelPreviewItem from './RelPreviewItem';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

const spySubscription = { values: true };

const ManyToManyReference = (props: any) => {
  const classes = useStyles();
  const { values } = useFormState({ subscription: spySubscription });
  return (
    <div className={classes.root}>
      {!!values.id && (
        <RelPreviewItem
          id={values.id}
          referenceT={props.reference}
          through={props.through}
          using={props.using}
          using2={props.using2}
        />
      )}
    </div>
  );
};

export default ManyToManyReference;
