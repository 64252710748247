import React, { useRef, useState } from "react";

import {
  Edit,
  BooleanInput,
  TextInput,
  TopToolbar,
  ListButton,
  FormDataConsumer,
  DateTimeInput,
} from 'react-admin';
import PreviewImage from '../../components/PreviewImage';
import { FormTab } from 'react-admin';
import { TabbedForm } from 'react-admin';
import { required } from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import ManyToManyReference from '../../components/manyToManyReference/ManyToManyReference';
import UploadFileS3 from '../../components/UploadFileS3';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const NewsEdit = (props: any) => {
  const [url, setUrl] = useState(null);
  const fieldRefImageUrl = useRef(null);

  const onchange = (data: any, p: any, formData: any, rest: any) => {
    setUrl(data);
    formData.image = data;
    rest.record.image = data;
    fieldRefImageUrl.current.focus()
  };

  return (
    <Edit title={<Title />} actions={<EditActions />} {...props}>
      <TabbedForm
        {...(props['location'] && props['location'].pathname.endsWith('/1')
          ? { toolbar: '' }
          : null)}
      >
        <FormTab label="Riepilogo">
          <TextInput disabled source="id" label="Id" fullWidth />
          <TextInput
            source="name"
            label={'Nome'}
            validate={[required()]}
            fullWidth
          />
          <DateTimeInput
            source="publication_begin"
            label={'Data di inizio pubblicazione'}
            validation={{ required: true }}
          />
          <DateTimeInput
            source="publication_end"
            label={'Data di fine pubblicazione'}
            validation={{ required: true }}
          />
          <RichTextInput
            source="description"
            label={'Descrizione'}
            validation={{ required: true }}
            fullWidth
          />
          <RichTextInput source="abstract" label={'Astratto'} fullWidth />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <>
                  <TextInput
                    {...rest}
                    {...props}
                    source="image"
                    validation={required()}
                    data-target={url}
                    style={{ width: '100%' }}
                    label={'URL immagine'}
                    inputRef={fieldRefImageUrl}
                  />
                  <UploadFileS3
                    accept={'image/*'}
                    handleFileInput={(e: any, p: any) => {
                      onchange(e, p, formData, rest);
                    }}
                  ></UploadFileS3>
                </>
              );
            }}
          </FormDataConsumer>
          <PreviewImage source="image" />
          <BooleanInput source="is_public" label="Pubblico?" />
          <BooleanInput source="highlights" label="In evidenzia?" />
        </FormTab>
        <FormTab label="Comuni associati">
          <ManyToManyReference
            source="news_id"
            reference="news_municipality"
            allowEmpty
            through="municipalities"
            using="news_id"
            using2="municipality_id"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default NewsEdit;
