import React, { useState, useEffect } from 'react';
import buildHasuraProvider from 'ra-data-hasura';
import customBuildFields from './custom-build-fields';
import packagejson from '../package.json';
import { createHttpLink, InMemoryCache } from '@apollo/client';
import {
  MunicipalitiesList,
  MunicipalitiesShow,
  MunicipalitiesCreate,
  MunicipalitiesEdit,
  HomeWorkIcon,
} from './resources/municipalities';
import {
  WastesList,
  WastesCreate,
  WastesShow,
  WastesEdit,
  DeleteSweepIcon,
} from './resources/wastes';
import {
  CategoriesList,
  CategoriesShow,
  CategoriesCreate,
  CategoriesEdit,
  FeaturedPlayListIcon,
} from './resources/categories';
import {
  RecyclingAreasShoe,
  RecyclingAreasList,
  RecyclingAreasCreate,
  RecyclingAreasEdit,
  LocationOnIcon,
} from './resources/recycling_areas';
import {
  NewsShow,
  NewsList,
  NewsCreate,
  NewsEdit,
  AnnouncementIcon,
} from './resources/news';
import {
  WasteMunicipalityCreate,
  WasteMunicipalityEdit,
  WasteMunicipalityList,
  WasteMunicipalityShow,
} from './resources/waste_municipality';
import { Admin } from 'react-admin';
import { Resource } from 'react-admin';
import { createHashHistory } from 'history';
import PostIcon from '@material-ui/icons/Book';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {
  NewsMunicipalityList,
  NewsMunicipalityShow,
  NewsMunicipalityCreate,
  NewsMunicipalityEdit,
} from './resources/news_municipality';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {
  RecyclingAreasMunicipalityCreate,
  RecyclingAreasMunicipalityEdit,
  RecyclingAreasMunicipalityList,
  RecyclingAreasMunicipalityShow,
} from './resources/recycling_areas_municipality';
import {
  CollectionPointsEdit,
  CollectionPointsCreate,
  CollectionPointsShow,
  CollectionPointsList,
} from './resources/collection_points';
import {
  CollectionPointWasteShow,
  CollectionPointWasteCreate,
  CollectionPointWasteEdit,
  CollectionPointWasteList,
} from './resources/collection_point_waste';
import {
  StaticContentList,
  StaticContentShow,
  StaticContentCreate,
  StaticContentEdit,
} from './resources/static_content';
import authProvider from './services/authProvider';
import LoginPage from './page/LoginPage';

import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  EcovanEdit,
  EcovanShow,
  EcovanCreate,
  EcovanList,
} from './resources/ecovan';
import { getCookie } from './utils/utils';
import {
  CalendarCreate,
  CalendarEdit,
  CalendarList,
  CalendarShow,
} from './resources/calendar';

import { onError } from 'apollo-link-error';
import {
  PublicIcon,
  SocialCreate,
  SocialEdit,
  SocialList,
  SocialShow,
} from './resources/social';

import raLanguageItalian from "ra-language-italian";
import { EcovanMunicipalityCreate, EcovanMunicipalityEdit, EcovanMunicipalityList, EcovanMunicipalityShow } from './resources/ecovan_municipality';

const extendTranslations = {
  ...raLanguageItalian,
  "Associazioni eliminate": "Associazioni eliminate!"
}

const GRAPHQL_URI = process.env.REACT_APP_API_URL + '/v1/graphql';

const token = getCookie('auth') as unknown as string;
const roles = getCookie('roles') as unknown as any[];

const {version} = packagejson;

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    ...(token !== null &&
      token !== '' &&
      token && { Authorization: `Bearer ${token}` }),
    ...(roles &&
      roles.length > 0 &&
      roles.includes('sensor-admin') && { 'X-Hasura-Role': 'sensor-admin' }),
  },
});

const errorLink: any = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${extensions?.code}`
      );
      if (extensions?.code === 'invalid-jwt') {
        authProvider.logout();
        window.location.reload();
      }
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const clientOptions: any = {
  uri: GRAPHQL_URI,
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache(),
};

const amiuTheme = merge({}, defaultTheme, {
  palette: {
    primary: indigo,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        // color: 'white', // Some CSS
      },
    },
    MuiAppBar: {
      root: {
        color: 'white',
      },
      colorSecondary: {
        backgroundColor: '#277c52',
      },
    },
  },
});

const history = createHashHistory();

function App() {
  const [dataProvider, setDataProvider] = useState<null | Function>(null);
  const i18nProvider = polyglotI18nProvider(() => extendTranslations, 'it');

  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider(
        { clientOptions },
        { buildFields: customBuildFields }
      );
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);


  if (!dataProvider) return <p>Caricamento...</p>;
  return (
      <Admin
        dataProvider={dataProvider}
        history={history}
        i18nProvider={i18nProvider}
        title="Amiu Admin"
        authProvider={authProvider}
        loginPage={LoginPage}
        theme={amiuTheme}
      >
        <Resource
          name="municipalities"
          options={{ label: 'Lista comuni' }}
          icon={HomeWorkIcon}
          list={MunicipalitiesList}
          show={MunicipalitiesShow}
          create={MunicipalitiesCreate}
          edit={MunicipalitiesEdit}
        />
        <Resource
          name="categories"
          options={{ label: 'Contenitori' }}
          icon={FeaturedPlayListIcon}
          list={CategoriesList}
          show={CategoriesShow}
          create={CategoriesCreate}
          edit={CategoriesEdit}
        />
        <Resource
          name="waste"
          icon={DeleteSweepIcon}
          options={{ label: 'Rifiuti' }}
          list={WastesList}
          show={WastesShow}
          create={WastesCreate}
          edit={WastesEdit}
        />
        <Resource
          name="waste_municipality"
          icon={PostIcon}
          list={WasteMunicipalityList}
          show={WasteMunicipalityShow}
          create={WasteMunicipalityCreate}
          edit={WasteMunicipalityEdit}
          options={{ label: 'Rifiuti - Comuni' }}
        />
        <Resource
          name="recycling_areas"
          icon={LocationOnIcon}
          list={RecyclingAreasList}
          show={RecyclingAreasShoe}
          create={RecyclingAreasCreate}
          edit={RecyclingAreasEdit}
          options={{ label: 'Isole ecologiche' }}
        />
        <Resource
          name="recycling_areas_municipality"
          icon={LocationOnIcon}
          list={RecyclingAreasMunicipalityList}
          show={RecyclingAreasMunicipalityShow}
          create={RecyclingAreasMunicipalityCreate}
          edit={RecyclingAreasMunicipalityEdit}
          options={{ label: 'Isole ecologiche - Comuni' }}
        />
        <Resource
          name="news"
          icon={AnnouncementIcon}
          list={NewsList}
          show={NewsShow}
          create={NewsCreate}
          edit={NewsEdit}
        />
        <Resource
          name="news_municipality"
          icon={PostIcon}
          list={NewsMunicipalityList}
          show={NewsMunicipalityShow}
          create={NewsMunicipalityCreate}
          edit={NewsMunicipalityEdit}
          options={{ label: 'News - Comuni' }}
        />
        <Resource
          name="collection_points"
          icon={PostIcon}
          list={CollectionPointsList}
          show={CollectionPointsShow}
          create={CollectionPointsCreate}
          edit={CollectionPointsEdit}
          options={{ label: 'Punti di raccolta' }}
        />
        <Resource
          name="collection_point_waste"
          icon={PostIcon}
          list={CollectionPointWasteList}
          show={CollectionPointWasteShow}
          create={CollectionPointWasteCreate}
          edit={CollectionPointWasteEdit}
          options={{ label: 'Rifiuti - Punti di raccolta' }}
        />
        <Resource
          name="static_content"
          icon={ReceiptIcon}
          list={StaticContentList}
          show={StaticContentShow}
          create={StaticContentCreate}
          edit={StaticContentEdit}
          options={{ label: 'Contenuti statici' }}
        />
        <Resource
          name="ecovan"
          icon={LocalShippingIcon}
          list={EcovanList}
          show={EcovanShow}
          create={EcovanCreate}
          edit={EcovanEdit}
          options={{ label: 'Ecovan (PDF)' }}
        />
        <Resource
          name="ecovan_municipality"
          icon={PostIcon}
          list={EcovanMunicipalityList}
          show={EcovanMunicipalityShow}
          create={EcovanMunicipalityCreate}
          edit={EcovanMunicipalityEdit}
          options={{ label: 'Ecovan - Comuni' }}
        />
        <Resource
          name="calendar"
          icon={DateRangeIcon}
          list={CalendarList}
          show={CalendarShow}
          create={CalendarCreate}
          edit={CalendarEdit}
          options={{ label: 'Calendari' }}
        />
        <Resource
          name="social"
          icon={PublicIcon}
          list={SocialList}
          show={SocialShow}
          create={SocialCreate}
          edit={SocialEdit}
          options={{ label: 'Social' }}
        />
        <div
          style={{
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 100,
            padding: 6,
            backgroundColor: '#efefef',
            textAlign: 'left',
          }}
        >
          <small>AMIU - Ver: {version}</small>
        </div>
      </Admin>

  );
}

export default App;
