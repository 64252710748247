import React from 'react';
import { DateField, ImageField } from "react-admin";
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  BooleanField,
  EditButton,
  RichTextField,
} from 'react-admin';
import PreviewImage from '../../components/PreviewImage';
import PreviewMunicipalities from './PreviewMunicipalities';
import { makeStyles } from '@material-ui/core/styles';

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  tableCell: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const CustomField = (props: any) => (
  <PreviewMunicipalities id={props.record.id}></PreviewMunicipalities>
);

export const NewsList = (props: any) => {
  const classes = useStyles();

  return (
    <List filters={<UserFilter />} bulkActionButtons={false} sort={{ field: 'updated_at', order: 'DESC' }} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" label={'Nome'} />
        <RichTextField
          source="description"
          headerClassName={classes.tableHeader}
          cellClassName={classes.tableCell}
          label={'Descrizione'}
        />
       <ImageField source="image" className={'max-h-5'} label={'Anteprima'}>
          <PreviewImage source="src" />
        </ImageField>
        <BooleanField source="is_public" label={'Pubblicato'} />
        <BooleanField source="highlights" label="In evidenzia?" />
        <DateField
          source="created_at"
          label={'Data creazione'}
          showTime
          locales="it-IT"
        />
        <DateField
          source="updated_at"
          label={'Ultima modifica'}
          showTime
          locales="it-IT"
        />
        <EditButton basePath="/news" />
      </Datagrid>
    </List>
  );
};

export default NewsList;
