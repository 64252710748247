import React from 'react';
import {
  Edit,
  BooleanInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
} from 'react-admin';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span> {record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Indietro" />
  </TopToolbar>
);

export const MunicipalitiesEdit = (props: object) => (
  <Edit title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" label="Id" />
      <TextInput source="name" />
      <TextInput source="ipa_code" />
      <BooleanInput source="is_public" label="Pubblicato" />
    </SimpleForm>
  </Edit>
);

export default MunicipalitiesEdit;
