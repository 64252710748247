import React from 'react';
import { ReferenceField } from 'react-admin';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  EditButton,
  DateField,
} from 'react-admin';

const RowFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

export const CalendarList = (props: object) => (
  <List filters={<RowFilter />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label={'Nome'} />
      <ReferenceField
        label="Comune"
        source="municipality_id"
        reference="municipalities"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField
        source="created_at"
        label={'Data creazione'}
        showTime
        locales="it-IT"
      />
      <DateField
        source="updated_at"
        label={'Ultima modifica'}
        showTime
        locales="it-IT"
      />
      <EditButton basePath="/calendar" label={'Modifica'} />
    </Datagrid>
  </List>
);

export default CalendarList;
