import React, { useRef, useState } from "react";
import { DateTimeInput, FormDataConsumer, ListButton } from "react-admin";
import { TopToolbar } from 'react-admin';
import { Create, BooleanInput, SimpleForm, TextInput } from 'react-admin';
import { required } from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import UploadFileS3 from "../../components/UploadFileS3";
const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuova News'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const NewsCreate = (props: object) => {
  const fieldRefImageUrl = useRef(null);
  const [url, setUrl] = useState(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.image = data;
    setUrl(data);
    fieldRefImageUrl.current.focus()
  };
  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          label={'Nome'}
          validate={[required()]}
          fullWidth
        />
        <DateTimeInput
          source="publication_begin"
          label={'Data di inizio pubblicazione'}
          validation={{ required: true }}
        />
        <DateTimeInput
          source="publication_end"
          label={'Data di fine pubblicazione'}
          validation={{ required: true }}
        />
        <RichTextInput
          source="description"
          label={'Descrizione'}
          validation={{ required: true }}
          fullWidth
        />
        <RichTextInput source="abstract" label={'Astratto'} fullWidth />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <TextInput {...rest} source="image" label={'Url file'} inputRef={fieldRefImageUrl} alwaysOn
                           fullWidth={true} style={{ width: '100%' }}/>
                <UploadFileS3
                  accept={'image/*'}
                  handleFileInput={(e: any, p: any) => {
                    onchange(e, p, formData);
                  }}
                ></UploadFileS3>
              </>
            );
          }}
        </FormDataConsumer>
        <BooleanInput source="is_public" label="Pubblico?" />
        <BooleanInput source="highlights" label="In evidenzia?" />
      </SimpleForm>
    </Create>
  );
}

export default NewsCreate;
