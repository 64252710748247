import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  EditButton,
  ReferenceField,
  ChipField,
} from 'react-admin';

const NameFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

export const RecyclingAreasMunicipalityList = (props: object) => (
  <List filters={<NameFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Comune"
        source="municipality_id"
        reference="municipalities"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Isola ecologica"
        source="recycling_areas_id"
        reference="recycling_areas"
      >
        <ChipField source="name" />
      </ReferenceField>
      <EditButton basePath="/recycling_areas_municipality" />
    </Datagrid>
  </List>
);

export default RecyclingAreasMunicipalityList;
