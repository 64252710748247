import React, { useState } from "react";
import { CircularProgress, Snackbar } from "@material-ui/core";
import axios from "axios";

const config = {
  dirName: 'uploads/news',
  s3Url: process.env.REACT_APP_S3_BUCKET,
  apiUrl: process.env.REACT_APP_API_UPLOAD_IMAGE,
};

const UploadFileS3 = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (file) => {
    if (file) {
      let formData = new FormData();
      formData.append('files', file);
      setIsLoading(true);
      await axios
        .post(config.apiUrl + '/storage/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-path': '/uploads/news/',
          },
        })
        .then((response) => {
          setIsLoading(false);
          setMessage('File caricato con successo!');
          setOpen(true);
          axios
            .get(config.apiUrl + '/storage/file/' + response.data[0].key)
            .then((r) => {
              props.handleFileInput(
                config.apiUrl + '/storage/file/' + response.data[0].key,
                props
              );
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          setMessage(err.message);
          setOpen(true);
        });
    } else {
      setMessage('Nessun file selezionato');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <input
        id={'file'}
        type="file"
        onChange={handleFileInput}
        accept={props.accept}
      />
      <button type={'button'} onClick={() => handleUpload(selectedFile)}>
        {' '}
        Carica file
      </button>
      {isLoading ? <CircularProgress disableShrink /> : null}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
      />
    </div>
  );
};

export default UploadFileS3;
