import jwt_decode from 'jwt-decode';
import { getCookie, removeCookie, setCookie } from '../utils/utils';

const authProvider: any = {
  login: ({ username, password }) => {
    const request = new Request(
      process.env.REACT_APP_API_URL + '/sensor/auth',
      {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject('Username o password errati!');
        }
        return response.json();
      })
      .then((auth) => {
        const decoded: any = jwt_decode(auth.token);
        if (
          decoded['https://hasura.io/jwt/claims'][
            'x-hasura-allowed-roles'
          ].includes('sensor-admin')
        ) {
          setCookie('auth', auth.token, decoded.exp);
          setCookie(
            'roles',
            decoded['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
            decoded.exp
          );
          return Promise.resolve();
        } else {
          return Promise.reject('Utente non abilitato');
        }
      });
  },
  logout: () => {
    removeCookie('auth');
    removeCookie('roles');
    return Promise.resolve('/login');
  },
  checkAuth: () =>
    getCookie('auth') && getCookie('roles').includes('sensor-admin')
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login' }),
  checkError: (error) => Promise.resolve(),
  getPermissions: (params) => Promise.resolve(),
  getIdentity: () => Promise.resolve(),
};

export default authProvider;
