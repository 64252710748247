import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  FormDataConsumer,
} from 'react-admin';
import { required } from 'ra-core';
import UploadFileS3 from '../../components/UploadFileS3';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo Ecovan'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const EcovanCreate = (props: object) => {
  const [url, setUrl] = useState(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.file = data;
    setUrl(data);
  };
  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput source="name" label={'Nome'} validate={[required()]} fullWidth />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <TextInput
                  {...rest}
                  source="file"
                  label={'Url file (PDF)'}
                  validate={[required()]}
                  style={{ width: '100%' }}
                />
                <UploadFileS3
                  accept={'application/pdf'}
                  handleFileInput={(e: any, p: any) => {
                    onchange(e, p, formData);
                  }}
                ></UploadFileS3>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default EcovanCreate;
