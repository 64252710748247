import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  BooleanField,
  EditButton,
  ImageField, RichTextField, ReferenceInput, SelectInput
} from "react-admin";
import { ReferenceField } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { required } from "ra-core";

const WastesFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
    <ReferenceInput
      label="Categoria"
      source="category_id"
      reference="categories"
    >
      <SelectInput
        source="name"
        validate={required()}
        alwaysOn fullWidth={true}
        style={{ width: '100%' }}
      />
    </ReferenceInput>
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  tableCell: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const WastesList = (props: object) => {
  const classes = useStyles();
  return (
    <List filters={<WastesFilter />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" label={'Nome'} />
        <RichTextField
          source="description"
          headerClassName={classes.tableHeader}
          cellClassName={classes.tableCell}
          label={'Descrizione'}
        />
        <ReferenceField
          label="Categoria"
          source="category_id"
          reference="categories"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="is_public" label={'Pubblicato'} />
        <ImageField
          source="image"
          title="Immagine"
          label={'Immagine'}
          className="thumbNailView"
        />
        <EditButton basePath="/waste" />
      </Datagrid>
    </List>
  );
}

export default WastesList

