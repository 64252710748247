import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
} from 'react-admin';
import { required } from 'ra-core';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span> {record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Indietro" />
  </TopToolbar>
);

export const SocialEdit = (props: object) => {
  const [url, setUrl] = useState(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.file = data;
    setUrl(data);
  };

  return (
    <Edit title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="Id" />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="url" validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};

export default SocialEdit;
