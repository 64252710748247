import React from 'react';
import {
  TextField,
  DateField,
  BooleanField,
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  ListButton,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const ShowActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const RecyclingAreasShoe = (props: object) => (
  <Show title={<Title />} actions={<ShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Riepilogo">
        <TextField source="id" />
        <TextField source="name" />
        <TextField label="Recapito telefonico" source="phone_number" />
        <JsonField
          source="opening_hours"
          addLabel={true}
          jsonString={false} // Set to true if the value is a string, default: false
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <BooleanField source="citizen_admitted" label="Rifiuti comuni" />
        <BooleanField source="business_admitted" label="Rifiuti aziendali" />
        <DateField label="Created At" source="created_at" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default RecyclingAreasShoe;
