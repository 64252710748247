import * as React from 'react';
import { useState } from 'react';
import { useNotify, Notification } from 'react-admin';
import {
  Button,
  Card,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import authProvider from '../services/authProvider';
import { useRedirect } from 'ra-core';
import { AccountCircle } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import { createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#094F9C',
      minWidth: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      padding: '25px',
      width: '300px',
    },
    center: {
      justifyContent: 'center',
      display: 'flex',
    },
    button: {
      marginTop: '20px',
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

const LoginPage = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    authProvider
      .login({ username, password })
      .then(() => {
        redirect('/municipalities');
      })
      .finally(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => {
        notify(e, 'error');
      });
  };

  return (
    <Grid className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={submit} style={{ width: '100%' }}>
          <div className={classes.margin}>
            <Grid container alignItems="flex-end" style={{ width: '100%' }}>
              <Grid item>
                <AccountCircle />
              </Grid>
              <Grid item style={{ width: '90%' }}>
                <TextField
                  id="username"
                  label="Username o Email"
                  type="email"
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  autoFocus
                  required
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.margin}>
            <Grid container alignItems="flex-end" style={{ width: '100%' }}>
              <Grid item>
                <LockIcon />
              </Grid>
              <Grid item style={{ width: '90%' }}>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="on"
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </div>
          <Grid className={classes.margin}>
          <div><a href={'https://segnalazioni.amiu.genova.it/userpaex/forgotpassword'} target={'_blank'}>Recupera password</a></div>
          </Grid>
          <div className={classes.center}>
            <Grid>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={submit}
              >
                Login
              </Button>
            </Grid>
          </div>
        </form>
      </Card>
      <Notification
        autoHideDuration={5000}
        shownotification={show ? show : false}
      />
    </Grid>
  );
};

export default LoginPage;
