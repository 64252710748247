import React, { useRef, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
} from 'react-admin';
import { required } from 'ra-core';
import UploadFileS3 from '../../components/UploadFileS3';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span> {record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Indietro" />
  </TopToolbar>
);

export const CalendarEdit = (props: object) => {
  const [url, setUrl] = useState(null);
  const fieldRefImageUrl = useRef(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.file = data;
    setUrl(data);
    fieldRefImageUrl.current.focus()
  };

  return (
    <Edit title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="Id" alwaysOn fullWidth={true} />
        <TextInput source="name" validate={[required()]} alwaysOn fullWidth={true} />
        <ReferenceInput
          label="Comune"
          source="municipality_id"
          reference="municipalities"
          sort={{ field: 'name', order: 'ASC'}}
          perPage="false"
        >
          <SelectInput source="name" validate={[required()]} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <TextInput
                  {...rest}
                  source="file"
                  label={'Url file (PDF)'}
                  validate={[required()]}
                  style={{ width: '100%' }}
                  alwaysOn fullWidth={true} inputRef={fieldRefImageUrl}
                />
                <UploadFileS3
                  accept={'application/pdf'}
                  handleFileInput={(e: any, p: any) => {
                    onchange(e, p, formData);
                  }}
                ></UploadFileS3>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default CalendarEdit;
