import React from 'react';
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  ListButton,
  ReferenceField,
} from 'react-admin';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const ShowActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const RecyclingAreasMunicipalityShow = (props: object) => (
  <Show title={<Title />} actions={<ShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Riepilogo">
        <TextField source="id" />
        <ReferenceField
          label="Comune"
          source="municipality_id"
          reference="municipalities"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Isola ecologica"
          source="recycling_areas_id"
          reference="recycling_areas"
        >
          <TextField source="name" />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default RecyclingAreasMunicipalityShow;
