import React from 'react';
import { ListButton } from 'react-admin';
import { TopToolbar } from 'react-admin';
import { Create, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';
const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const NewsMunicipalityCreate = (props: object) => (
  <Create title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <ReferenceInput source="municipality_id" reference="municipalities">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="news_id" reference="news">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default NewsMunicipalityCreate;
