import React from 'react';
import {
  Datagrid,
  TextField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  TopToolbar,
  ListButton,
  BooleanField,
} from 'react-admin';

const UserTitle = ({ record }: { record?: { name: string } }) => {
  return <span>User: {record ? `${record.name}` : ''}</span>;
};

const UserShowActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const NewsShow = (props: object) => (
  <Show title={<UserTitle />} actions={<UserShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Riepilogo">
        <TextField source="id" />
        <TextField source="name" />
        <DateField label="Created At" source="created_at" />
      </Tab>

      <Tab label="comuni" path="municipalities">
        <ReferenceManyField
          reference="municipalities"
          target="id"
          addLabel={false}
        >
          <Datagrid rowClick="edit">
            <TextField source="id" label="Id comune" />
            <TextField source="name" />
            <BooleanField source="highlights" label="In evidenzia?" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default NewsShow;
