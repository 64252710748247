import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  ReferenceField,
  ChipField,
  EditButton,
} from 'react-admin';

const TabFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

export const NewsMunicipalityList = (props: object) => (
  <List filters={<TabFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Comune"
        source="municipality_id"
        reference="municipalities"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField label="Rifiuti" source="news_id" reference="news">
        <ChipField source="name" />
      </ReferenceField>
      <EditButton basePath="/news_municipality" />
    </Datagrid>
  </List>
);

export default NewsMunicipalityList;
