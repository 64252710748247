import React, { useRef, useState } from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  ReferenceInput,
  FormDataConsumer,
} from 'react-admin';
import { required } from 'ra-core';
import UploadFileS3 from '../../components/UploadFileS3';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo Calendario porta a porta'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const CalendarCreate = (props: object) => {
  const [url, setUrl] = useState(null);
  const fieldRefImageUrl = useRef(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.file = data;
    setUrl(data);
    fieldRefImageUrl.current.focus()
  };

  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput source="name" label={'Nome'} validate={[required()]} alwaysOn fullWidth={true} />
        <ReferenceInput
          label="Comune"
          source="municipality_id"
          reference="municipalities"
          sort={{ field: 'name', order: 'ASC'}}
          perPage="false"
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <TextInput
                  {...rest}
                  source="file"
                  label={'Url file (PDF)'}
                  validate={[required()]}
                  alwaysOn fullWidth={true} inputRef={fieldRefImageUrl}
                  style={{ width: '100%' }}
                />
                <UploadFileS3
                  accept={'application/pdf'}
                  handleFileInput={(e: any, p: any) => {
                    onchange(e, p, formData);
                  }}
                ></UploadFileS3>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default CalendarCreate;
