import React, { useMemo, useRef, useState } from 'react';
import {
  Edit,
  BooleanInput,
  TextInput,
  TopToolbar,
  ListButton,
  FormDataConsumer,
  FormTab,
  TabbedForm,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { required } from 'ra-core';
import { Button, Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ManyToManyReference from '../../components/manyToManyReference/ManyToManyReference';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useForm } from 'react-final-form';
import { searchReverseLocation } from '../../services/geocode-reverse';
import RichTextInput from 'ra-input-rich-text';
import { LatLngExpression } from 'leaflet';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    input: {
      display: 'block',
      width: '350px',
    },
    fullHeightButton: {
      height: '56px',
      alignSelf: 'center',
      top: '16px',
    },
  })
);

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const provider = new OpenStreetMapProvider({
  params: {
    'accept-language': 'it', // render results in Dutch
    countrycodes: 'it', // limit search results to the Netherlands
    addressdetails: 1, // include additional address detail parts
    extratags: 1,
    namedetails: 1,
  },
});

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const CollectionPointsEdit = (props: object) => {
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<string | null>(options[0]);
  const [geocode, setGeocode] = useState<LatLngExpression | null>(
    options[0]
  );
  const [map, setmap] = useState(null);
  const [open, setOpen] = useState(false);
  if (map) {
    map.panTo(geocode);
  }
  let center: any = { lat: 0, lng: 0 };
  if (geocode && geocode !== null) {
    center = {
      lat: geocode[0],
      lng: geocode[1],
    };
  }
  const classes = useStyles();

  function DraggableMarker() {
    const [position, setPosition] = useState(center);
    const [label, setLabel] = useState(null);
    const markerRef = useRef(null);
    const form = useForm();

    let formdata = form.getState().values;

    if (!label) {
      setLabel(formdata.address);
    }

    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker._latlng);
            searchReverseLocation({
              latitude: marker._latlng.lat,
              longitude: marker._latlng.lng,
            }).then((r) => {
              setLabel(r.label);
              form.change('address', r.label);
              form.change(
                'location',
                '(' + marker._latlng.lat + ',' + marker._latlng.lng + ')'
              );
            });
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup minWidth={90}>
          <span>{label}</span>
        </Popup>
      </Marker>
    );
  }

  const defaultPosition: LatLngExpression = [44.4471372, 8.7507472];
  return (
    <Edit title={<Title />} actions={<EditActions />} {...props}>
      <TabbedForm
        {...(props['location'] && props['location'].pathname.endsWith('/1')
          ? { toolbar: null }
          : null)}
      >
        <FormTab label="Riepilogo">
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} md={6}>
              <TextInput
                disabled
                source="id"
                label="Id"
                className={classes.input}
                alwaysOn fullWidth={true}
              />
              <TextInput
                source="name"
                className={classes.input}
                label={'Nome'}
                validate={required()}
                alwaysOn fullWidth={true}
              />
              <RichTextInput
                source="description"
                label={'Descrizione'}
                className={classes.input}
                fullWidth
              />
              <ReferenceInput
                label="Tipo"
                source="category_id"
                reference="categories"
                perPage="false"
                sort={{ field: 'name', order: 'ASC'}}
              >
                <SelectInput
                  source="name"
                  validate={required()}
                  className={classes.input}
                  alwaysOn fullWidth={true}
                />
              </ReferenceInput>
              <TextInput
                source="address"
                validate={required()}
                disabled
                className={classes.input}
                label={'Indirizzo'}
                alwaysOn fullWidth={true}
                style={{ width: '100%' }}
              />
              <TextInput
                source="location"
                validate={required()}
                disabled
                className={classes.input}
                label={'Coordinate'}
                alwaysOn fullWidth={true}
                style={{ width: '100%' }}
              />
              <FormDataConsumer>
                {({
                  formData,
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) => {
                  if (formData) {
                    if (!geocode) {
                      setGeocode(
                        formData.location
                          .split(/[,\()/]/)
                          .filter((item) => item.trim() !== '')
                          .map(parseFloat)
                      );
                    }
                  }

                  async function search(data: any) {
                    let s = data.inputProps.value;
                    if (s.length > 3) {
                      await provider
                        .search({
                          query: s,
                        })
                        .then((res) => {
                          setOpen(false);
                          setOptions(res);
                          setOpen(true);
                        });
                    }
                  }

                  return (
                    <Autocomplete
                      {...rest}
                      {...props}
                      onChange={(event: any, newValue: any | null) => {
                        if (newValue) {
                          formData.location =
                            '(' + newValue.y + ',' + newValue.x + ')';
                          formData.address = newValue.label;
                          setValue(newValue.label);
                          setGeocode([newValue.y, newValue.x]);
                          setOpen(false);
                        }
                      }}
                      options={options && options.length > 0 ? options : []}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => {
                        return (
                          <Grid container={true}>
                            <Grid item xs={6}>
                              <TextInput
                                {...params}
                                label="Cerca indirizzo"
                                margin="normal"
                                variant="outlined"
                                source={'address'}
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            </Grid>
                            <Grid>
                              <Button
                                classes={{ root: classes.fullHeightButton }}
                                variant="contained"
                                color="primary"
                                onClick={() => search({ ...params })}
                              >
                                Cerca
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      }}
                      open={open}
                      noOptionsText={'Nessun risultato!'}
                    />
                  );
                }}
              </FormDataConsumer>
              <BooleanInput source="is_public" label="Pubblico" />
            </Grid>
            <Grid item xs={12} md={6}>
              <MapContainer
                center={geocode ? geocode : defaultPosition}
                zoom={18}
                scrollWheelZoom={false}
                whenCreated={setmap}
              >
                <TileLayer
                  attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker />
              </MapContainer>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Rifiuti associati">
          <ManyToManyReference
            source="collection_point_id"
            reference="collection_point_waste"
            allowEmpty
            through="waste"
            using="collection_point_id"
            using2="waste_id"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CollectionPointsEdit;
