import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  EditButton,
  DateField,
} from 'react-admin';

const RowFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

export const SocialList = (props: object) => (
  <List filters={<RowFilter />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label={'Nome'} />
      <TextField source="url" label={'Url'} />
      <DateField
        source="created_at"
        label={'Data creazione'}
        showTime
        locales="it-IT"
      />
      <DateField
        source="updated_at"
        label={'Ultima modifica'}
        showTime
        locales="it-IT"
      />
      <EditButton basePath="/social" label={'Modifica'} />
    </Datagrid>
  </List>
);

export default SocialList;
