import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  ReferenceField,
  ChipField,
  EditButton,
} from 'react-admin';

const TabFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Search by Name" source="name" alwaysOn />
  </Filter>
);

export const WasteMunicipalityList = (props: object) => (
  <List filters={<TabFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Comune"
        source="municipality_id"
        reference="municipalities"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField label="Rifiuti" source="waste_id" reference="waste">
        <ChipField source="name" />
      </ReferenceField>
      <EditButton basePath="/waste_municipality" />
    </Datagrid>
  </List>
);

export default WasteMunicipalityList;
