import React, { useRef, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  SelectInput,
  ReferenceInput,
  FormDataConsumer, FormTab
} from "react-admin";
import { required } from 'ra-core';
import UploadFileS3 from '../../components/UploadFileS3';
import ManyToManyReference from "../../components/manyToManyReference/ManyToManyReference";
import { TabbedForm } from 'react-admin';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span> {record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} label="Indietro" />
  </TopToolbar>
);

export const EcovanEdit = (props: object) => {
  const [url, setUrl] = useState(null);
  const fieldRefImageUrl = useRef(null);


  const onchange = (data: any, p: any, formData: any,rest: any) => {
    setUrl(data);
    formData.file = data;
    rest.record.image = data;
    fieldRefImageUrl.current.focus()
  };

  return (
    <Edit title={<Title />} actions={<EditActions />} {...props}>
      <TabbedForm
        {...(props['location'] && props['location'].pathname.endsWith('/1')
          ? { toolbar: '' }
          : null)}
      >
        <FormTab label="Riepilogo">
        <TextInput disabled source="id" label="Id" fullWidth />
        <TextInput source="name" validate={[required()]} fullWidth/>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <TextInput
                  {...rest}
                  {...props}
                  source="file"
                  label={'Url file (PDF)'}
                  validate={[required()]}
                  fullWidth
                  style={{ width: '100%' }}
                  inputRef={fieldRefImageUrl}
                />
                <UploadFileS3
                  accept={'application/pdf'}
                  handleFileInput={(e: any, p: any) => {
                    onchange(e, p, formData, rest);
                  }}
                ></UploadFileS3>
              </>
            );
          }}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Comuni associati">
        <ManyToManyReference
          source="ecovan_id"
          reference="ecovan_municipality"
          allowEmpty
          through="municipalities"
          using="ecovan_id"
          using2="municipality_id"
        />
      </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EcovanEdit;
