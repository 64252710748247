import React from 'react';
import { CreateButton } from 'react-admin';
import { ExportButton } from 'react-admin';
import { TopToolbar } from 'react-admin';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  BooleanField,
  EditButton,
  DateField,
  ImageField,
} from 'react-admin';

const NameFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

const ListActions = (props) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ExportButton {...props} />
    </TopToolbar>
  );
};

export const CategoriesList = (props: object) => (
  <List filters={<NameFilter />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label={'Nome'} />
      <BooleanField source="is_public" label={'Pubblicato'} />
      <ImageField
        source="image"
        title="Immagine"
        label={'Immagine'}
        className="thumbNailView"
      />
      <DateField
        source="created_at"
        label={'Data creazione'}
        showTime
        locales="it-IT"
      />
      <DateField
        source="updated_at"
        label={'Ultima modifica'}
        showTime
        locales="it-IT"
      />
      <EditButton basePath="/categories" />
    </Datagrid>
  </List>
);

export default CategoriesList;
