import React, { useRef, useState } from "react";
import {
  FormDataConsumer,
  ListButton,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { TopToolbar } from 'react-admin';
import { Create, BooleanInput, SimpleForm, TextInput } from 'react-admin';
import { required } from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import UploadFileS3 from '../../components/UploadFileS3';
const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo rifiuto'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const WastesCreate = (props: object) => {
  const fieldRefImageUrl = useRef(null);
  const [url, setUrl] = useState(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.image = data;
    setUrl(data);
    fieldRefImageUrl.current.focus()
  };
  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput source="name" label={'Nome'} validate={[required()]} alwaysOn fullWidth={true}/>
        <RichTextInput source="description" label={'Descrizione'} />
        <ReferenceInput
          label="Categoria"
          source="category_id"
          reference="categories"
          validate={[required()]}
          perPage="false"
          sort={{ field: 'name', order: 'ASC'}}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <TextInput {...rest} source="image" label={'Url file'} inputRef={fieldRefImageUrl} alwaysOn fullWidth={true} style={{ width: '100%' }}/>
                <UploadFileS3
                  accept={'image/*'}
                  handleFileInput={(e: any, p: any) => {
                    onchange(e, p, formData);
                  }}
                ></UploadFileS3>
              </>
            );
          }}
        </FormDataConsumer>
        <BooleanInput source="is_public" label="Pubblico" />
      </SimpleForm>
    </Create>
  );
};

export default WastesCreate;
