import React, { useRef, useState } from "react";
import {
  Create,
  BooleanInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  FormDataConsumer,
} from 'react-admin';
import { required } from 'ra-core';
import UploadFileS3 from '../../components/UploadFileS3';
import PreviewImage from "../../components/PreviewImage";
const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo tipo contenitore'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const CategoriesCreate = (props: object) => {
  const fieldRefImageUrl = useRef(null);
  const [url, setUrl] = useState(null);


  const onchange = (data: any, p: any, formData: any) => {
    formData.image = data;
    setUrl(data);
    fieldRefImageUrl.current.focus()
  };
  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput source="name" label={'Nome'} validate={[required()]} alwaysOn fullWidth={true}/>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <TextInput {...rest} source="image" label={'Url file'} alwaysOn fullWidth={true} inputRef={fieldRefImageUrl} style={{ width: '100%' }}/>
                <UploadFileS3
                  accept={'image/*'}
                  handleFileInput={(e: any, p: any) => {
                    onchange(e, p, formData);
                  }}
                ></UploadFileS3>
              </>
            );
          }}
        </FormDataConsumer>
        <PreviewImage source="image" />
        <BooleanInput source="is_public" label="Pubblico" />
      </SimpleForm>
    </Create>
  );
};

export default CategoriesCreate;
