import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  TextField,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const StaticContentEdit = (props: object) => (
  <Edit title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" label="Id" />
      <TextField source="name" label="Nome" disabled />
      <RichTextInput source="content" label={'Contenuto'} rows={'5'} />
    </SimpleForm>
  </Edit>
);

export default StaticContentEdit;
