import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  EditButton,
  RichTextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const StaticContentFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  tableCell: {
    maxWidth: 300,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export const StaticContentList = (props: object) => {
  const classes = useStyles();
  return (
    <List
      filters={<StaticContentFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label={'Nome'} />
        <RichTextField
          source="content"
          headerClassName={classes.tableHeader}
          cellClassName={classes.tableCell}
          label={'Descrizione'}
        />
        <EditButton basePath="/static_content" />
      </Datagrid>
    </List>
  );
};

export default StaticContentList;
