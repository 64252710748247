import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  BooleanField,
  EditButton,
} from 'react-admin';

const NameFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

export const RecyclingAreasList = (props: object) => (
  <List filters={<NameFilter />} bulkActionButtons={false} {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <TextField source="name" label={'Nome'} />
      <TextField label="Recapito telefonico" source="phone_number" />
      <BooleanField source="is_public" label={'Pubblicato'} />
      <BooleanField source="citizen_admitted" label="Rifiuti comuni" />
      <BooleanField source="business_admitted" label="Rifiuti aziendali" />
      <EditButton basePath="/recycling_areas" />
    </Datagrid>
  </List>
);

export default RecyclingAreasList;
