import React from 'react';
import { ChipField, ReferenceField } from "react-admin";
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  EditButton,
} from 'react-admin';

const RowFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
  </Filter>
);

export const EcovanMunicipalityList = (props: object) => (
  <List filters={<RowFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Comune"
        source="municipality_id"
        reference="municipalities"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Ecovan"
        source="ecovan_id"
        reference="ecovan"
      >
        <ChipField source="name" />
      </ReferenceField>
      <EditButton basePath="/ecovan_municipality" />
    </Datagrid>
  </List>
);

export default EcovanMunicipalityList;
