import React, { useRef, useState } from "react";
import {
  Edit,
  BooleanInput,
  TextInput,
  TopToolbar,
  ListButton,
  ReferenceInput,
  SelectInput,
  FormTab,
  FormDataConsumer,
} from 'react-admin';
import { required } from 'ra-core';
import ManyToManyReference from '../../components/manyToManyReference/ManyToManyReference';
import { TabbedForm } from 'react-admin';
import UploadFileS3 from '../../components/UploadFileS3';
import RichTextInput from 'ra-input-rich-text';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

export const NonInput = React.memo(function NonInput({ children }): any {
  return children;
});

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const WastesEdit = (props: object) => {
  const fieldRefImageUrl = useRef(null);
  const [url, setUrl] = useState(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.image = data;
    setUrl(data);
    fieldRefImageUrl.current.focus()
  };

  return (
    <Edit title={<Title />} actions={<EditActions />} {...props}>
      <TabbedForm
        {...(props['location'] && props['location'].pathname.endsWith('/1')
          ? { toolbar: '' }
          : null)}
      >
        <FormTab label="Riepilogo">
          <TextInput disabled source="id" label="Id" alwaysOn fullWidth={true} inputRef={fieldRefImageUrl}/>
          <TextInput source="name" label="Nome" validate={[required()]} alwaysOn fullWidth={true} inputRef={fieldRefImageUrl}/>
          <RichTextInput source="description" label="Descrizione" rows={'5'}/>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <>
                  <TextInput {...rest} source="image" label={'Url file'} alwaysOn fullWidth={true} inputRef={fieldRefImageUrl} style={{ width: '100%' }}/>
                  <NonInput>
                    <UploadFileS3
                      accept={'image/*'}
                      handleFileInput={(e: any, p: any) => {
                        onchange(e, p, formData);
                      }}
                    ></UploadFileS3>
                  </NonInput>
                </>
              );
            }}
          </FormDataConsumer>
          <ReferenceInput
            label="Categoria"
            source="category_id"
            reference="categories"
            validate={[required()]}
            perPage="false"
            sort={{ field: 'name', order: 'ASC'}}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput source="is_public" label="Pubblico" />
        </FormTab>
        <FormTab label="Comuni associati">
          <NonInput>
            <ManyToManyReference
              source="waste_id"
              reference="waste_municipality"
              allowEmpty
              through="municipalities"
              using="waste_id"
              using2="municipality_id"
            />
          </NonInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default WastesEdit;
