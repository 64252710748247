import React, { useEffect } from "react";
import {
  LinearProgress
} from 'react-admin';
import { Checkbox } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Loading } from 'react-admin';
import { DataProvider } from "ra-core";

const RelPreviewItem = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider: DataProvider = useDataProvider();
  const [checked, setChecked] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  let listData = []

  const handleClick = (item: any) => {
    if(item.id_ref === null){
      dataProvider
        .create(props.referenceT, {
          data: {
            [props.using]: props.id,
            [props.using2]: item.id,
          },
        })
        .then((response: any) => {
          let index_create = checked.findIndex(
            (el) => el.id === response.data[props.using2]
          );
          checked[index_create].id_ref = response.data.id;
          checked[index_create].isChecked = true;

          setChecked(checked);
          notify('Elemento aggiunto');
            refresh();
        })
        .catch((error: { message: any }) => {
          // failure side effects go here
          notify(`Ci sono stati degli errori: ${error.message}`, 'warning');
        });
    }else{
      dataProvider
        .delete(props.referenceT, { id: item.id_ref })
        .then((response: any) => {
          notify('Elemento eliminato');
            refresh();
        })
        .catch((error: { message: any }) => {
          // failure side effects go here
          notify(`Ci sono stati degli errori: ${error.message}`, 'warning');
        });
    }
  };

  const handleClickDeselectAll = (values) => {
    let ids = [];
    values.map(el => {
      ids.push(el.id_ref)
    })
    ids = ids.filter(Boolean);
      dataProvider.deleteMany(props.referenceT,
        { ids }).then((response: any) => {
        notify('Associazioni eliminate');
        refresh();
      }).catch((error: { message: any }) => {
        notify(`Ci sono stati degli errori: ${error.message}`, 'warning');
      })
  }

  const handleClickSelectAll = (values) => {
    //Search ids by delete
   let ids = [];
   debugger
    values.map(el => {
      ids.push(el.id_ref)
    })
    ids = ids.filter(Boolean)
   dataProvider.deleteMany(props.referenceT,
     { ids: ids }).then((response: any) => {
     createMulti(values)
   }).catch((error: { message: any }) => {
     notify(`Ci sono stati degli errori: ${error.message}`, 'warning');
   })
  }

  const createMulti = async (values) => {
    setLoading(true)
    await Promise.all(
      values.map((value, index) =>
        setTimeout(() => {
          dataProvider
            .create(props.referenceT, {
              data: {
                [props.using]: props.id,
                [props.using2]: checked[index].id
              }
            })
            .catch((err) => {
              setLoading(false)
            })
        }, 2)
      )
    ).finally( () => {
      notify('Associazioni completate!');
      setTimeout(() => {
        setLoading(false)
      refresh();
      }, 1000)
    })
  }

  const TagsField: ({ data: values }: { data: any }) => JSX.Element = ({
    data: values,
  }) => {
    return (
      <>
        {loading === true ?
        <Loading />
          :  ''}
        {loading === false ?
          <>
            {values && values.length > 0 && checked.every((e) => e.isChecked === true) ? (
            <Checkbox
              onClick={() => handleClickDeselectAll(values)}
              className="d-block mb-3"
              color="primary-o"
            >
              Deseleziona tutto
            </Checkbox>
          ): (
            <div className={'flex-align'}>
            <Checkbox
              onClick={() => handleClickSelectAll(values)}
              className="d-block mb-3"
              color="primary-o"
            >
              Seleziona tutto
            </Checkbox>
            </div>
          )}
        <hr/>
        {values && values.length > 0 && typeof values[0] != 'undefined' ? (
          values.map(
            (
              item: {
                id: React.Key | null | undefined;
                name: string
              },
              index: string | number
            ) => {
              return (
                <Checkbox
                  onChange={() => handleClick(item)}
                  className="d-block mb-3"
                  checked={checked[index].isChecked || false}
                  color="primary-o"
                  key={item.id}
                >
                  {item.name}
                </Checkbox>
              );
            }
          )
        ) : (
          <LinearProgress />
        )} </> : ''}
      </>
    );
  };

  useEffect(() => {
    dataProvider.getList(props.through,{ pagination: { page: 1 , perPage: 1000 }, sort: { field: 'created_at', order: 'ASC' }, filter: {}})
      .then(({ data }) => {
        listData = data.sort((a, b) => a.name.localeCompare(b.name));
        if(listData.length){
          dataProvider.getList(props.referenceT, {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'created_at', order: 'ASC' },
            filter: { [props.using]: props.id }
          })
            .then(({ data }) => {
              if (listData.length === 0) {
                setChecked([...listData].map(object => {
                  return {
                    ...object,
                    isChecked: false
                  }
                }))
              }else{
                if(listData.length){
                  setChecked([...listData].map(object => {
                    return {
                      ...object,
                      isChecked: !!data.filter(el => el[props.using] === props.id && el[props.using2] === object.id).length,
                      id_ref: data.filter(el => el[props.using] === props.id && el[props.using2] === object.id).length ?
                        data.filter(el => el[props.using] === props.id && el[props.using2] === object.id)[0].id : null
                    }
                  }))
                }
              }
            })
        }
      })
  }, []);

  return (
    <div className="customComponent">
      <TagsField data={checked} />
    </div>
  );
};

export default RelPreviewItem;
