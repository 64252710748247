import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
} from 'react-admin';
import { required } from 'ra-core';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo Social'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const SocialCreate = (props: object) => {
  const [url, setUrl] = useState(null);

  const onchange = (data: any, p: any, formData: any) => {
    formData.file = data;
    setUrl(data);
  };
  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <TextInput source="name" label={'Nome'} validate={[required()]} />
        <TextInput source="url" label={'Url Link'} validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};

export default SocialCreate;
