import React from 'react';
import {
  Create,
  BooleanInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
} from 'react-admin';
import { required } from 'ra-core';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo Comune'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const MunicipalitiesCreate = (props: object) => (
  <Create title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput source="name" label={'Nome'} validate={[required()]} />
      <TextInput source="ipa_code" label={'Codice IPA'} />
      <BooleanInput source="is_public" label="Pubblicato" />
    </SimpleForm>
  </Create>
);

export default MunicipalitiesCreate;
