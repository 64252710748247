import React from 'react';
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TopToolbar,
  ListButton,
} from 'react-admin';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const NewsMunicipalityEdit = (props: object) => (
  <Edit title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Comuni"
        source="municipality_id"
        reference="municipalities"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Rifiuti" source="news_id" reference="news">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default NewsMunicipalityEdit;
