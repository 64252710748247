import React from 'react';
import {
  Create,
  ListButton,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TopToolbar,
} from 'react-admin';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const RecyclingAreasMunicipalityCreate = (props: object) => (
  <Create title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <ReferenceInput source="municipality_id" reference="municipalities">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="recycling_areas_id" reference="recycling_areas">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default RecyclingAreasMunicipalityCreate;
