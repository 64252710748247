import React from 'react';
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TopToolbar,
  ListButton,
} from 'react-admin';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const RecyclingAreasMunicipalityEdit = (props: object) => {
  return (
    <Edit title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Comuni"
          source="municipality_id"
          reference="municipalities"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Isola ecologica"
          source="recycling_areas_id"
          reference="recycling_areas"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default RecyclingAreasMunicipalityEdit;
