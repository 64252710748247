import React, { useMemo, useRef, useState } from 'react';
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  FormTab,
  ListButton,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  TopToolbar,
} from 'react-admin';
import { required } from 'ra-core';
import { Button, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useForm } from 'react-final-form';
import { searchReverseLocation } from '../../services/geocode-reverse';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import RichTextInput from 'ra-input-rich-text';
import { LatLngExpression } from 'leaflet';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo punto di raccolta'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const NonInput = React.memo(function NonInput({ children }): any {
  return children;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    input: {
      display: 'block',
      width: '350px',
    },
    fullHeightButton: {
      height: '56px',
      alignSelf: 'center',
      top: '16px',
    },
  })
);

const provider = new OpenStreetMapProvider({
  params: {
    'accept-language': 'it', // render results in Dutch
    countrycodes: 'it', // limit search results to the Netherlands
    addressdetails: 1, // include additional address detail parts
    extratags: 1,
    namedetails: 1,
  },
});

export const CollectionPointsCreate = (props: object) => {
  const [options, setOptions] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<any>(false);
  const [geocode, setGeocode] = React.useState<LatLngExpression>([
    44.4471372, 8.7507472,
  ]);
  const [map, setmap] = useState(null);
  const [open, setOpen] = useState(false);
  const mapsCanvas = useRef(HTMLElement);

  if (map) {
    map.panTo(geocode);
  }
  let center = { lat: 44.4471372, lng: 8.7507472 };
  if (geocode != null) {
    center = {
      lat: geocode[0],
      lng: geocode[1],
    };
  }
  const classes = useStyles();

  function DraggableMarker() {
    const [position, setPosition] = useState(center);
    const [label, setLabel] = useState(null);
    const markerRef = useRef(null);
    const form = useForm();

    let formdata = form.getState().values;

    if (!label && formdata && formdata.address) {
      setLabel(formdata.address);
    }

    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker._latlng);
            searchReverseLocation({
              latitude: marker._latlng.lat,
              longitude: marker._latlng.lng,
            }).then((r) => {
              setLabel(r.label);
              form.change('address', r.label);
              form.change(
                'location',
                '(' + marker._latlng.lat + ',' + marker._latlng.lng + ')'
              );
            });
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup minWidth={90}>
          <span>{label}</span>
        </Popup>
      </Marker>
    );
  }

  const defaultPosition: LatLngExpression = [44.4471372, 8.7507472];
  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <TabbedForm>
        <FormTab label="Crea">
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} md={6}>
              <TextInput
                source="name"
                className={classes.input}
                label={'Nome'}
                validate={required()}
                alwaysOn fullWidth={true}
                style={{ width: '100%' }}
              />
              <RichTextInput
                source="description"
                label={'Descrizione'}
                className={classes.input}
                alwaysOn fullWidth={true}
              />

              <ReferenceInput
                label="Tipo"
                source="category_id"
                reference="categories"
                perPage="false"
                sort={{ field: 'name', order: 'ASC'}}
              >
                <SelectInput
                  source="name"
                  validate={required()}
                  className={classes.input}
                  alwaysOn fullWidth={true}
                  style={{ width: '100%' }}
                />
              </ReferenceInput>
              <TextInput
                source="address"
                className={classes.input}
                label={'Indirizzo'}
                validate={required()}
                alwaysOn fullWidth={true}
                style={{ width: '100%' }}
                disabled
              />
              <TextInput
                source="location"
                validate={required()}
                disabled
                className={classes.input}
                label={'Coordinate'}
                alwaysOn fullWidth={true}
                style={{ width: '100%' }}
              />
              <BooleanInput source="is_public" label="Pubblico?" />

              <FormDataConsumer>
                {({
                  formData,
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) => {
                  if (formData) {
                    if (!geocode && formData.location) {
                      setGeocode(
                        formData.location
                          .split(/[,\()/]/)
                          .filter((item) => item.trim() !== '')
                          .map(parseFloat)
                      );
                    }
                  }

                  function search(data: any) {
                    let s = data.inputProps.value;
                    if (s.length > 3) {
                      provider
                        .search({
                          query: s,
                        })
                        .then((res) => {
                          setOpen(false);
                          setOptions(res);
                          setOpen(true);
                          setTimeout(() => {
                            document.getElementById('map').click();
                          }, 1000);
                        });
                    }
                  }

                  return (
                    <NonInput>
                      <Autocomplete
                        {...rest}
                        {...props}
                        style={{ width: '100%' }}
                        id={'searchMaps'}
                        onChange={(event: any, newValue: any | null) => {
                          if (newValue) {
                            formData.location =
                              '(' + newValue.y + ',' + newValue.x + ')';
                            formData.address = newValue.label;
                            setValue(newValue.label);
                            setGeocode([newValue.y, newValue.x]);
                            setOpen(false);
                          }
                        }}
                        options={options || []}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => {
                          return (
                            <Grid container={true}>
                              <Grid item xs={6}>
                                {' '}
                                <TextInput
                                  {...params}
                                  label="Cerca indirizzo"
                                  margin="normal"
                                  variant="outlined"
                                  source={'address'}
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                  }}
                                  alwaysOn fullWidth={true}
                                />
                              </Grid>
                              <Grid>
                                <Button
                                  classes={{ root: classes.fullHeightButton }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => search({ ...params })}
                                >
                                  Cerca
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        }}
                        open={open}
                        noOptionsText={'Nessun risultato!'}
                      />
                    </NonInput>
                  );
                }}
              </FormDataConsumer>
            </Grid>
            <Grid item xs={12} md={6}>
              <MapContainer
                center={geocode ? geocode : defaultPosition}
                zoom={18}
                scrollWheelZoom={false}
                whenCreated={setmap}
                id={'map'}
              >
                <TileLayer
                  attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker />
              </MapContainer>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CollectionPointsCreate;
