import React from 'react';
import {
  Create,
  SelectInput,
  SimpleForm,
  TopToolbar,
  ListButton,
  ReferenceInput,
} from 'react-admin';

const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo Ecovan'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const EcovanMunicipalityCreate = (props: object) => {
  return (
    <Create title={<Title />} actions={<EditActions />} {...props}>
      <SimpleForm>
        <ReferenceInput source="municipality_id" reference="municipalities">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="ecovan_id" reference="ecovan">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default EcovanMunicipalityCreate;
