import React from 'react';
import { ListButton } from 'react-admin';
import { TopToolbar } from 'react-admin';
import { Create, SimpleForm, TextInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
const Title = ({ record }: { record?: { name: string } }) => {
  return <span>{'Nuovo contenuto statico'}</span>;
};
const EditActions = ({
  basePath,
  data,
}: {
  basePath?: string;
  data?: object;
}) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);
export const StaticContentCreate = (props: object) => (
  <Create title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm>
      <TextInput source="name" label={'Nome'} />
      <RichTextInput source="content" label={'Contenuto'} />
    </SimpleForm>
  </Create>
);

export default StaticContentCreate;
