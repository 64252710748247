import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  BooleanField,
  EditButton,
  ReferenceField,
  DateField, ReferenceInput, SelectInput
} from "react-admin";
import { required } from "ra-core";

const NameFilter = (props: object) => (
  <Filter {...props}>
    <TextInput label="Cerca per nome" source="name" alwaysOn />
    <ReferenceInput
      label="Tipo"
      source="category_id"
      reference="categories"
    >
      <SelectInput
        source="name"
        validate={required()}
        alwaysOn fullWidth={true}
        style={{ width: '100%' }}
      />
    </ReferenceInput>
  </Filter>
);

export const CollectionPointsList = (props: object) => (
  <List filters={<NameFilter />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label={'Nome'} />
      <TextField source="address" label={'Indirizzo'} />
      <TextField source="description" label={'Descrizione'} />
      <ReferenceField label="Tipo" source="category_id" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <DateField
        source="created_at"
        label={'Data creazione'}
        showTime
        locales="it-IT"
      />
      <BooleanField source="is_public" label={'Pubblico'} />
      <EditButton basePath="/collection_points" />
    </Datagrid>
  </List>
);

export default CollectionPointsList;
